<template>
    <div class="front-cover page-layout">
        <div class="left-side-ornament">
            <svg
                height="2550"
                viewBox="0 0 377 2550"
                width="377"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    :fill="brand.color"
                    d="m-1 0h1l377 375.3v2174.7h-378z" />
            </svg>
        </div>
        <div class="content">
            <div class="report-meta">
                <p class="title">
                    <slot name="title" />
                </p>
                <div class="item account">
                    <p class="label">
                        Account:
                    </p>
                    <p class="value">
                        <slot name="account" />
                    </p>
                </div>
                <div class="item date-range">
                    <p class="label">
                        Date Range:
                    </p>
                    <p class="value">
                        <slot name="date-range" />
                    </p>
                </div>
            </div>
            <div class="prepared-by">
                <p class="label">
                    Prepared by:
                </p>
                <div class="logo">
                    <slot name="logo" />
                </div>
            </div>
            <div
                v-if="$slots['badge']"
                class="badge">
                <slot name="badge" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FrontCoverLayout',
    props: {
        brand: {
            type: Object,
            required: true
        },
        title: {
            type: String,
            default: 'Monthly Facebook Performance Report'
        }
    }
};
</script>

<style lang="scss" scoped>
.front-cover {
    .left-side-ornament {
        position: absolute;
        left: 0;
        top: 0;
        width: 150px;
        height: 100%;
        overflow: hidden;
    }
    .content {
        margin-top: 275px;
        margin-left: 200px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 525px;
    }
    .report-meta {
        .title {
            font-size: 40px !important;
            font-weight: 400;
            color: $white;
        }
        .item {
            margin-top: 20px;
            .label {
                color: $gray;
                font-size: 28px;
                font-weight: 600;
            }
            .value {
                color: $white;
                font-size: 28px;
                font-weight: 600;
                margin-top: -20px;
            }
        }
    }
    .prepared-by {
        .label {
            font-size: 24px;
            font-weight: 600;
            color: $gray;
        }
        .logo {
            margin-top: -5px;
            height: 60px;
            img {
                height: 100%;
            }
        }
    }
    .badge {
        position: absolute;
        bottom: 15px;
        right: 35px;
        height: auto;
        width: 120px;
        img {
            height: 100%;
            width: 100%;
        }
    }
}
</style>